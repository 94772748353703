.speciality-label {
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide overflowed text */
    text-overflow: ellipsis; /* Add ellipsis */
    max-width: 200px; /* Set a maximum width for the text */
    display: inline-block; /* Ensure the ellipsis works */
  }

  @media (max-width: 768px) {
    /* .doct-pro {
      display: flex;
      flex-direction: column; 
      align-items: flex-start; 
    } */
  
    /* .speciality-label {
      max-width: 100%; 
      white-space: normal; 
      overflow: visible; 
    } */
  }

  @media (max-width: 768px) {
    .speciality-chip {
      white-space: normal; /* Allow wrapping */
      text-overflow: unset;
      overflow: visible;
    }
  }


  /* Shimmer css */

  /* .shimmer-profile {
    width: 100%;
  } */

  .shimmer-profile-image {
    display: block;
    margin-right: auto;
    margin-left: auto;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    background: linear-gradient(
      90deg,
      #f0f0f0 25%,
      #e0e0e0 50%,
      #f0f0f0 75%
    );
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
  }

  .shimmer-chip {
    width: 300px;
    height: 30px;
    border-radius: 5px;
    background: linear-gradient(
      90deg,
      #f0f0f0 25%,
      #e0e0e0 50%,
      #f0f0f0 75%
    );
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
  }