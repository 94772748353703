/* Container */
.terms-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

/* Header */
.header-title {
    padding: 1.5rem;
    text-align: center;
    font-size: 1.8rem;
    font-weight: bold;
}

/* Main Content */
.terms-content {
    flex: 1;
    /* max-width: 800px; */
    padding: 0px 128px;;
    margin: 2rem auto;
    border-radius: 8px;
}

.terms-content section {
    margin-bottom: 1.5rem;
}

.terms-content h2 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: #354895;
}

.terms-content p {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    color: #555;
}

.terms-content a {
    color: #0073e6;
    text-decoration: none;
}

.terms-content a:hover {
    text-decoration: underline;
}

@media (min-width: 769px) and (max-width: 1024px) { 
    .terms-content {
        padding: 0px 83px;
    }
}
@media (min-width: 581px) and (max-width: 768px) {
    .terms-content {
        padding: 0px 15px;
    }
}
@media (min-width: 481px) and (max-width: 580px) {
    .terms-content {
        padding: 0px 15px;
    }
}
@media (min-width: 200px) and (max-width: 480px) {
    .terms-content {
        padding: 0px 15px;
    }
}