.error-page {
    display: flex;
    align-items: center;
    justify-items: center;
    background-color: #e6f6fe;
    height: 100vh;
    padding: 20px;
    gap: 10px; /* Space between grid cells */
    justify-content: space-between;
  }
  
  .error-content {
    text-align: center;
  }
  
  .error-title {
    font-size: 80px;
    color: #354895;
    font-weight: bold;
    margin-bottom: 16px;
  }
  
  .error-subtitle {
    font-size: 36px;
    color: #333;
    margin-bottom: 16px;
  }
  
  .error-description {
    font-size: 18px;
    color: #666;
    margin-bottom: 32px;
  }
  
  .error-actions {
    display: flex;
    justify-content: center;
    gap: 16px;
  }
  
  .error-btn {
    padding: 12px 24px;
    font-size: 16px;
    background-color: #01adec;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .error-btn:hover {
    background-color: #007bbf;
  }
  
  .doctor-illustration {
    width: 100%;
    max-width: 300px;
    max-height: 500px;
  }
  
  .ambulance-illustration {
    width: 100%;
    max-width: 300px;
    max-height: 300px;
  }
  