.doctor-title {
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
}

.doctor-name {
  font-size: 24px;
  font-weight: 700;
  line-height: 28.8px;
  letter-spacing: 0.02em;
  margin-bottom: 0.5rem;
}
.view-profile-btn {
  width: 120px;
  height: 46px;
  border-radius: 8px;
  background: white;
  border: 1px solid #354895;
  box-shadow: 0px 8px 16px 0px #82828229;
  color: #354895;
}
.profile-name {
  font-size: 16px !important;
  font-weight: 400 !important;
}
.doct-name {
  font-size: 24px !important;
  font-weight: 700 !important;
}
.chip-label {
  font-size: 16px !important;
}
.book-appoinment-btn {
  margin-right: 10px;
  padding: 10px;
  height: 46px;
  border-radius: 8px;
  background: #354895;
  border: 1px solid #354895;
  box-shadow: 0px 8px 16px 0px #82828229;
  color: white;
}
.book-appoinment-btn-view {
  margin-right: 10px;
  padding: 10px;
  height: 46px;
  border-radius: 8px;
  background: #354895;
  border: 1px solid #354895;
  box-shadow: 0px 8px 16px 0px #82828229;
  color: white;
}

.doctor-list-subtitle {
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.02em;
}
.doct-details h6 {
  font-size: 24px;
  font-weight: 700;
}
.doct-details p {
  margin-bottom: 5px;
  font-size: 18px;
}

.doctor-list-subtitle > span {
  color: #011632;
  font-weight: 700;
}

.doctor-view-btn {
  width: 120px;
  height: 46px;
  border-radius: 8px;
  background: #ffffff;
  border: 1px solid #354895;
  box-shadow: 0px 8px 16px 0px #82828229;
}

.doctor-appointment-btn {
  width: 200px;
  height: 46px;
  border-radius: 8px;
  background: #354895;
  box-shadow: 0px 12px 20px 0px #82828233;
  animation-duration: 0ms;
}

.doctor-search input {
  width: 532px;
  height: 56px;
  border-radius: 8px;
  background: #ffffff;
  border: none;
  box-shadow: 0px 6px 10px 0px #0000001f;
  padding-left: 1rem;
  opacity: 0.36px;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
}

.view-profile-img {
  width: 241px;
  height: 241px;
}

/* doctors details  */
.about-section {
  background-color: #e9f5ff;
  border-radius: 12px;
  padding: 25px 35px;
  margin-bottom: 24px;
}

.about-section .about-heading {
  font-weight: bold;
  color: #354895;
  margin-bottom: 12px;
}

.about-section .about-description {
  font-size: 16px;
  color: #333333;
  margin-bottom: 8px;
}

.about-section .about-more {
  font-weight: bold;
  color: #354895;
  cursor: pointer;
  margin-bottom: 0px;
}

@media (min-width: 1024px) and (max-width: 1536px) {
  .doct-img {
    width: 176px !important;
    height: 176px !important;
  }
}
@media (max-width: 576px) {
  .filter-drop {
    text-align: center !important;
  }

  .filterSpecialist {
    margin-top: 15px !important;
    width: 100% !important;
  }
  .doct-details {
    justify-content: center !important;
  }

}

@media (min-width: 1200px) and (max-height: 1536px) {
  .doctor-list-sec-data {
    width: 64%;
  }
}
 
@media (max-width:1024px){
  .view-profile-btn {
    width: 112px;
    font-size: 13px;
  }
  .book-appoinment-btn {
    font-size: 12px;
  }
}