
.privacy-container {
    padding: 63px 128px;
}
/* Header */
.header-privacy {
    padding: 1.5rem;
    text-align: center;
    font-weight: bold;
}

/* Main Content */
.privacy-content {
    flex: 1;
    /* max-width: 800px; */
    /* padding: 0px 128px; */
    margin: 2rem auto;
    border-radius: 8px;
}

.privacy-content p {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    color: #555;
}

@media (min-width: 769px) and (max-width: 1024px) { 
    .privacy-container {
        padding: 0px 83px;
    }
}
@media (min-width: 581px) and (max-width: 768px) {
    .privacy-container {
        padding: 0px 15px;
    }
    .privacy-content p {
        font-size: 1rem;
    }
}
@media (min-width: 481px) and (max-width: 580px) {
    .privacy-container{
        padding: 0px 15px;
    }
    .privacy-content p {
        font-size: 0.8rem;
    }
}
@media (min-width: 200px) and (max-width: 480px) {
    .privacy-container{
        padding: 0px 15px;
    }
}