.footer-wrapper {
  background: url("../../assets/images/image.png") no-repeat center center;
  background-size: cover;
  background-color: #0b184e;
  padding: 22px 128px !important;
}

.footer {
  color: white;
  margin-top: 32px;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
}

.footer-section {
  text-align: left;
}

.footer-logo {
  width: 162px;
  margin-bottom: 14px;
}
.footer-description {
  margin: 20px 0;
}

.social-icons a {
  margin-right: 10px;
  color: white;
  font-size: 20px;
  text-decoration: none;
}

.footer-title {
  margin-bottom: 15px;
  font-weight: bold;
}

.footer-links {
  list-style: none;
  padding: 0;
  margin-top: 30px;
}

.footer-links li {
  margin: 12px 0;
  margin-left: 2px;
  font-size: 16px;
  font-weight: 400;
}
.footer-links a {
  text-decoration: none;
  color: white;
}

.footer-contact {
  margin: 10px 0;
}

.footer-bottom {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-top: 1px solid rgba(255, 255, 255, 0.2); */
  padding-top: 15px;
  flex-wrap: wrap;
}

/* Back to Top Link Styling */
.back-to-top {
  margin-left: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #354895;
  background-color: #01abeb;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-decoration: none;
  transition: all 0.3s ease;
}

/* Arrow Icon Styling */
.arrow-icon {
  font-size: 20px;
  color: white;
}

.footer-contact {
  margin: 10px 0;
  /* display: flex;
  gap: 31px;
  flex-wrap: wrap; */
}
i.fa-brands.fa-facebook-f {
  background-color: white;
  color: #354895;
  padding: 7px;
  border-radius: 17px;
  padding-inline: 11px;
}
i.fa-brands.fa-twitter {
  background-color: white;
  color: #354895;
  padding: 7px;
  border-radius: 17px;
  padding-inline: 7px;
}
i.fa-brands.fa-linkedin-in {
  background-color: white;
  color: #354895;
  padding: 7px;
  border-radius: 17px;
  padding-inline: 9px;
}
.footer-contact-list{
  list-style: none;
  margin-top: 30px;
  padding-left: 1rem;
}
@media (max-width: 768px) {
  .footer-wrapper {
    padding: 16px !important;
  }
  .footer {
    margin-top: 16px !important;
  }
  .footer-section {
    margin-bottom: 0px !important;
  }
  .social-icons {
    margin-top: 2rem !important;
  }
  .printing {
    width: auto !important;
    font-size: 15px;
    padding: 16px;
  }
  .footer-title {
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-top: 16px !important;
  }
  .footer-links {
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-top: 16px !important;
    margin-bottom: 0px !important;
  }
  .footer-links li {
    margin: 8px !important;
  }
  .footer-contact-list {
    padding-left: 0 !important;
    margin-top: 16px !important;
  }
  .footer-bottom {
    margin-top: 16px !important;
    padding-top: 0px !important;
  }
  .footer-bottom p {
    font-size: 14px !important;
    margin-bottom: 10px;
  }
  .footer-contact p {
    font-size: 15px;
  }
  .footer-description{
    font-size: 15px !important;
    margin-top: 16px !important;
  }
}

i.fa-brands.fa-instagram {
  background-color: white;
  color: #354895; /* Instagram's primary color */
  padding: 7px;
  border-radius: 17px;
  padding-inline: 11px;
}

i.fa-brands.fa-youtube {
  background-color: white;
  color: #354895; /* YouTube's primary color */
  padding: 7px;
  border-radius: 17px;
  padding-inline: 8px;
}
