.breadcrumbs-list {
  /* width: max-content;
      overflow-y: auto; */
  /* overflow: hidden; */
  width: max-content;
  overflow-y: auto;
}

/* =====================2=========================== */
.breadcrumbs-wrap {
  position: absolute;
  /* top: 75%; */
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  /*  */
  /* width: 100%; */
  padding: 0 16px;
  box-sizing: border-box;
}

.breadcrumb {
  /* border: 2px solid; */
  width: max-content !important;
  display: block;
  border-radius: 47px;
  padding: 8px;
  padding-inline: 30px;
  color: white;
  background-color: #4f67cb69;
  margin-top: 52px;
  text-decoration: none;
}

li.MuiBreadcrumbs-separator.css-1wuw8dw-MuiBreadcrumbs-separator {
  color: #ffff;
  font-size: 20px;
}

.breadcrumbs a {
  text-decoration: none;
  /* font-weight: 600 !important; */
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: white;
  font-size: 15px;
  /* padding-right: 8px; */
}

.active-link-color {
  color: var(--text-color) !important;
  text-transform: uppercase;
  font-size: 12px !important;
  font-weight: 600 !important;
  letter-spacing: 0.5px;
  /*  */
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  width: max-content;
  margin-bottom: -5px;
}

.breadcrumbs ol {
  -webkit-flex-wrap: nowrap !important;
}

.MuiBreadcrumbs-ol > li:nth-child(3)  {
  display: block;
  overflow: hidden;
  color: white;
  text-overflow: ellipsis;
  width: auto;
}
/* ======================3======================== */

@media (min-width: 360px) and (max-width: 399.98px) {
  .breadcrumbs-wrap {
    top: 100%;
    width: auto;
  }
  .breadcrumbs {
    height: 20px;
  }
  .breadcrumbs a {
    font-size: 10px;
  }
  .active-link-color {
    font-size: 10px !important;
  }
  .breadcrumbs a {
    padding-right: 0px;
  }
  .active-link-color {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    width: 65px;
    margin-bottom: -5px;
  }

  /* .active-link-color {
        font-size: 10px !important;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 44%;
      } */
  /* .breadcrumbs-list {
        width: max-content !important;
        overflow-y: auto !important;
      } */
}
@media (min-width: 400px) and (max-width: 449.98px) {
  .breadcrumbs-wrap {
    top: 100%;
    width: auto;
  }
  .breadcrumbs {
    height: 20px;
  }
  .breadcrumbs a {
    font-size: 10px;
  }
  .active-link-color {
    font-size: 10px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    width: 65px;
    margin-bottom: -5px;
  }
  .MuiBreadcrumbs-separator {
    margin-left: 6px !important;
    margin-right: 6px !important;
  }
}
@media (min-width: 450px) and (max-width: 499.98px) {
  .breadcrumbs-wrap {
    top: 100%;
    width: auto;
  }
  .breadcrumbs {
    height: 20px;
  }
  .breadcrumbs a {
    font-size: 10px;
  }
  .active-link-color {
    font-size: 10px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    width: 65px;
    margin-bottom: -5px;
  }
  .MuiBreadcrumbs-ol > li:nth-child(3) {
    max-width: 50px;
  }
}
@media (min-width: 500px) and (max-width: 549.98px) {
  .breadcrumbs-wrap {
    top: 100%;
    width: auto;
  }
  .breadcrumbs {
    height: 20px;
  }
  .breadcrumbs a {
    font-size: 10px;
  }
  .active-link-color {
    font-size: 10px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    width: 65px;
    margin-bottom: -4px;
  }
  .MuiBreadcrumbs-ol > li:nth-child(3) {
    max-width: 50px;
  }
}
@media (min-width: 550px) and (max-width: 574.98px) {
  .breadcrumbs-wrap {
    top: 100%;
    width: auto;
  }
  .breadcrumbs {
    height: 20px;
  }
  .breadcrumbs a {
    font-size: 10px;
  }
  .active-link-color {
    font-size: 10px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    width: 65px;
    margin-bottom: -5px;
  }
  .MuiBreadcrumbs-ol > li:nth-child(3) {
    max-width: 50px;
  }
}
@media (min-width: 575px) and (max-width: 599.98px) {
  .breadcrumbs {
    padding: 0px 10px 0px 10px;
  }

  .breadcrumbs-wrap {
    top: 100%;
    width: auto;
  }
  .breadcrumbs {
    height: 20px;
  }
  .breadcrumbs a {
    font-size: 10px;
  }
  .active-link-color {
    font-size: 10px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    width: 65px;
    margin-bottom: -5px;
  }
  .MuiBreadcrumbs-ol > li:nth-child(3) {
    max-width: 50px;
  }
}
@media (min-width: 600px) and (max-width: 649.98px) {
  .breadcrumbs-wrap {
    top: 100%;
    width: auto;
  }
  .breadcrumbs {
    height: 20px;
  }
  .breadcrumbs a {
    font-size: 10px;
  }
  .active-link-color {
    font-size: 10px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    width: 65px;
    margin-bottom: -5px;
  }
  .MuiBreadcrumbs-ol > li:nth-child(3) {
    max-width: 60px;
  }
}
@media (min-width: 650px) and (max-width: 699.98px) {
  .breadcrumbs-wrap {
    top: 100%;
    width: auto;
  }
  .breadcrumbs {
    height: 20px;
  }
  .breadcrumbs a {
    font-size: 12px;
  }
  .active-link-color {
    font-size: 12px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    width: 65px;
    margin-bottom: -6px;
  }
  .MuiBreadcrumbs-ol > li:nth-child(3) {
    max-width: 70px;
  }
}
@media (min-width: 700px) and (max-width: 749.98px) {
  .breadcrumbs-wrap {
    top: 100%;
    width: auto;
  }
  .breadcrumbs {
    height: 20px;
  }
  .breadcrumbs a {
    font-size: 12px;
  }
  .active-link-color {
    font-size: 12px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    width: 65px;
    margin-bottom: -6px;
  }
  .MuiBreadcrumbs-ol > li:nth-child(3) {
    max-width: 80px;
  }
}
@media (min-width: 750px) and (max-width: 767.98px) {
  .breadcrumbs-wrap {
    top: 100%;
    width: auto;
  }
  .breadcrumbs {
    height: 20px;
  }
  .breadcrumbs a {
    font-size: 12px;
  }
  .active-link-color {
    font-size: 12px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    width: 65px;
    margin-bottom: -6px;
  }
  .MuiBreadcrumbs-ol > li:nth-child(3) {
    max-width: 80px;
  }
}
@media (min-width: 768px) and (max-width: 899.99px) {
  .breadcrumbs-wrap {
    top: 100%;
    width: auto;
  }
  .breadcrumbs {
    height: 20px;
  }
  .breadcrumbs a {
    font-size: 12px;
  }
  .active-link-color {
    font-size: 12px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    width: 65px;
    margin-bottom: -6px;
  }
  .MuiBreadcrumbs-ol > li:nth-child(3) {
    max-width: 80px;
  }
}
@media (min-width: 900px) and (max-width: 990px) {
  .breadcrumbs-wrap {
    top: 100%;
    width: auto;
  }
  .active-link-color {
    font-size: 12px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    width: 65px;
    margin-bottom: -6px;
  }
  .MuiBreadcrumbs-ol > li:nth-child(3) {
    max-width: 150px;
  }
}
@media (min-width: 991px) and (max-width: 992px) {
  .breadcrumbs-wrap {
    /* top: 50%; */
    top: 100%;
    width: auto;
  }
  .active-link-color {
    font-size: 12px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    width: 65px;
    margin-bottom: -6px;
  }
}
@media (min-width: 993px) and (max-width: 1199px) {
  .breadcrumbs-wrap {
    /* top: 75%; */
    top: 100%;
  }
  .breadcrumbs {
    height: 20px;
  }
  .breadcrumbs a {
    font-size: 12px;
  }
  .active-link-color {
    font-size: 12px !important;
  }
  .MuiBreadcrumbs-ol > li:nth-child(3) {
    max-width: 180px;
  }
}
