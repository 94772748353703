.Specialties-key {
  color: #354895;
  font-size: 24px;
  font-weight: 500;
}
.tittle-name {
  font-size: 18px;
  font-weight: 700;
}
.specialty-icon {
  text-align: center;
}
.specialties-section {
  padding: 0px 128px;
}
.specialist-subcategory {
  display: flex;
  flex-wrap: auto;
}
@media (max-width: 468px) {
  .specialist-subcategory {
    flex-direction: column;
  }
  .specialist-subcategory button:first-child {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
  .specialist-subcategory button:last-child {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
}
.specialist-subcategory button {
  width: 100%;
  background-color: white;
  color: #333333;
  padding: 10px;
  border: 1px solid #cacaca;
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  font-size: 16px;
}
/* Remove right box-shadow if there's a next sibling */
.specialist-subcategory button:not(:last-child) {
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 0px 0px;  */
  box-shadow: 0px 15px 10px -15px rgba(100, 100, 111, 0.2) 
}
.specialist-subcategory button:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  box-shadow: -15px 15px 10px -15px rgba(100, 100, 111, 0.2) /* left box shadow*/
}

.specialist-subcategory button:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 15px 15px 10px -15px rgba(100, 100, 111, 0.2);
}
.health-spec-title h2 {
  font-size: 36px;
  line-height: 45px;
  font-weight: 400;
}
.best-ent-h5 {
  font-weight: 700 !important;
  font-size: 36px !important;
}
.ent-treatment-box {
  background-color: #e6f6fe;
  padding: 30px;
  border-radius: 12px;
  overflow: auto;
  /* scrollbar-width: thin; */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}
.ent-treatment-box h6 {
  font-size: 24px;
  font-weight: 700;
  color: #01abeb;
}
.ent-treatment-box p {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}
.doct-list-sec {
  background-color: #354895;
  color: white;
  padding: 30px 0px 80px 0px;
  position: relative;
}
.ent-Procedures-box {
  background-color: #edf0ff;
  padding: 30px;
  border-radius: 12px;
  overflow: auto;
  /* scrollbar-width: thin; */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}
.ent-Procedures-box h6 {
  font-size: 24px;
  font-weight: 700;
  color: #354895;
}
.doct-list-sec .slick-prev {
  left: -44px !important;
  top: 40%;
}

.doct-list-sec .slick-next {
  right: -44px;
}
.slider-doct-detail p {
  margin-bottom: 5px;
  font-size: 16px !important;
}
.book-appoin-btn {
  background-color: #00aff0 !important;
}


.slick-arrow {
  z-index: 10;
}

.slider-box {
  height: 80px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  background-color: #00aff0;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  cursor: pointer;
}

.active-slider-box{
  background-color: #fff;
  color: #00AFF0;
  border: 1px solid #00AFF0;
}

.active-slider-box:hover {
  background-color: #fff !important;
}
.slider-box:hover {
  background-color: #0077cc; /* Add hover effect */
}

  /* Default positioning for left arrow */
.arrow-left {
  left: -66px; /* Default position for large screens */
}

/* Default positioning for right arrow */
.arrow-right {
  right: -15px;
}
/* Media query for smaller screens */
@media (max-width: 768px) {
  /* Adjust the position of the left arrow on smaller screens */
  .arrow-left {
    left: -25px; /* Adjusted position */
  }
  
  /* Adjust the position of the right arrow on smaller screens */
  .arrow-right {
    right: -10px; /* Adjusted position */
  }
}
@media (max-width: 580px) {
  /* Adjust the position of the left arrow on smaller screens */
  .arrow-left {
    left: -15px; /* Adjusted position */
  }
  
  /* Adjust the position of the right arrow on smaller screens */
  .arrow-right {
    right: -10px; /* Adjusted position */
  }
}

.slick-slide {
  /* padding: 0px 10px; */
}
/* .slick-prev, .slick-next {
  background: "#000" !important;
} */

/* .commit-sec .slick-slider .slick-prev {
  left: -44px !important;
  top: 40%;
}
.commit-sec .slick-slider .slick-next {
  right: -44px !important;
  top: 40%;
} */

.whyChooseUs-img {
  width: 100%;
  height: 300px;
  border-radius: 8px 8px 0px 0px;
}

.hospital-image {
  /* position: relative; */
  /* border-radius: 0% 0% 0% 0% / 0% 0% 0% 0% ; */
  border-radius: 8px;
  box-shadow: 35px 35px rgba(13,178,239,1);
  transition: all .4s ease;
  width: 400px;
}


@media (max-width: 768px) {
  .slider-doct-detail {
    flex-direction: column; /* Switch to column on smaller screens */
    align-items: center; /* Center align the items */
    text-align: center; /* Center align the text */
  }
  .whyChooseUs-img {
    height: 249px;
    border-radius: 0 !important;
  }
  .health-spec-title h2 {
    font-size: 25px;
  }
  .doct-pro-sec{
    flex-wrap: wrap;
  }
}
@media (min-width:768px) and (max-width:1024px){
  .whyChooseUs-img {
    height: 249px;
    border-radius: 0 !important;
  }
  .health-spec-title h2 {
    font-size: 30px;
  }
  .doct-pro-sec{
    flex-wrap: wrap;
  }
}
@media (max-width:420px) {
  .hospital-image {
    width: 60%;
    box-shadow: 15px 15px rgba(13,178,239,1);
  }
}
@media (min-width: 420px) and (max-width:580px) {
  .hospital-image {
    width: 70%;
    box-shadow: 15px 15px rgba(13,178,239,1);
  }
}
@media (min-width: 580px) and (max-width:768px) {
  .hospital-image {
    width: 250px;
    box-shadow: 20px 20px rgba(13,178,239,1);
  }
}
@media (min-width: 768px) and (max-width:898px) {
  .hospital-image {
    width: 250px;
    box-shadow: 20px 20px rgba(13,178,239,1);
  }
}
@media (min-width:898px) and (max-width:980px){
  .hospital-image {
    width: 250px;
    box-shadow: 20px 20px rgba(13,178,239,1);
  }
}
@media (min-width:980px) and (max-width:1024px){
  .hospital-image {
    width: 300px;
    box-shadow: 20px 20px rgba(13,178,239,1);
  }
}
@media (min-width:1024px) and (max-width:1200px){
  .hospital-image {
    width: 350px;
  }
}
.disease-slider .slick-list .slick-track .slick-slide {
  padding: 0px 10px;

}
.disease-slider {
  margin-top: -4rem;
}
.content-image {
  width: 100%;
}

.image-container {
  position: relative; /* This will allow absolute positioning of text */
  width: 100%;
}

.background-image {
  width: 100%; /* Full width of the container */
}

.text-container {
  position: absolute; /* Position text on top of the image */
  top: 50%; /* Vertically center */
  left: 144px; /* Padding on the left */
  transform: translateY(-50%); /* Perfectly center the content vertically */
  color: white;

}

.text-title {
  font-size: 50px;
  font-weight: 900;
  color: #06b0f0; /* Customize as needed */
  max-width: 724px; 
}

.text-paragraph {
  font-size: 24px;
  color: #fff;
  text-overflow: ellipsis;
  margin-bottom: 10px;
}

.breadcrumb {
  margin-top: 0px;
  margin-bottom: 0px;

}
@media (min-width: 1300px) and (max-width: 1536px)
{
  .text-title {  
    font-size: 32px;
    max-width: 90%;
  }
  .text-paragraph{
    font-size: 22px;
  }
}
@media (min-width: 1025px) and (max-width: 1299px)  {
  .text-title {  
    font-size: 26px;
    width: 80%;
  }
  .text-paragraph{
    font-size: 16px;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .text-container {
    left: 83px;
  }
  .text-title {  
    font-size: 20px;
    max-width: 77%;
  }
  .text-paragraph{
    font-size: 14px;
  }
}
@media (min-width: 581px) and (max-width: 768px) {
  .text-container {
    left: 10px;
  }
  .text-title {  
    font-size: 18px;
    max-width: 77%;
  }
  .text-paragraph{
    font-size: 12px;
    margin-bottom: 0px;
  }
  .breadcrumb {
    display: none;
  }
}
@media (min-width: 481px) and (max-width: 580px) {
  .text-container {
    left: 10px;
  }
  .text-title {  
    font-size: 16px;
    max-width: 60%;
  }
  .text-paragraph{
    font-size: 10px;
    margin-bottom: 0px;
  }
  .breadcrumb {
    display: none;
  }
}
@media (min-width: 200px) and (max-width: 480px) {
  .text-container {
    left: 10px;
  }
  .text-title {  
    font-size: 12px;
    max-width: 70%;
  }
  .text-paragraph{
    font-size: 9px;
    margin-bottom: 0px;
  }
}