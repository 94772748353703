.navbar-nav {
  display: flex;
}
a.nav-link {
  font-weight: 500 !important;
}

.nav-wrap {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}
.navbar_wrap {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}
.navbar {
  height: 80px;
  background-color: #fff;

  font-weight: 300 !important;
  padding: 5px 140px;
}

.navbar-right-wrap {
  display: flex;
  align-items: center;
}
li.nav-item {
  margin-top: 14px;
}
/* li.nav-link {
  color: 
} */
.nav-text h6 {
  color: var(--global-color);
  font-weight: 600;
  font-size: 20px;
  max-width: 265px;
  word-wrap: break-word;
}

.navbar-brand {
  padding: 0px 0px 0px 0px;
}

.nav-icon {
  margin-top: -3px;
}

.navbar-logo {
  max-width: 150px; /* Set maximum width */
  max-height: 60px; /* Set maximum height */
  width: auto; /* Maintain natural aspect ratio */
  height: auto; /* Maintain natural aspect ratio */
  object-fit: contain; /* Ensure the image fits properly */
  display: block; /* Prevent inline image alignment issues */
  margin: 0 auto; /* Center the logo if necessary */
}

.navbar-leftside {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 13px;
  text-transform: uppercase;
  color: #b5090e;
  font-weight: 600;
}

.navbar-leftside li {
  padding-right: 10px;
}

.navbar-leftside li:hover {
  cursor: pointer;
}

.navbar-nav .nav-link:hover {
  color: #4a4a49;
}

.toggler-icon {
  color: var(--accent-color);
}

.navbar-toggler:focus {
  box-shadow: none;
}

.dropdown-menu {
  background-color: #fff;
  min-width: 230px;
  box-shadow: 0px 10px 50px 0px rgba(53, 82, 99, 0.09);
  border: 0px;
  /* margin-top: px; */
  border-radius: 0;
}

.nav-link.donation {
  background-color: var(--text-color);
  color: #fff;
  cursor: pointer;
  border: 1px solid var(--text-color);
  border-radius: 2px;
  text-align: center;
  width: 125px;
  height: 42px;
  /* width: 140px;
        height: 50px; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-left: 10px; */
  border-radius: 30px;
  transition: all 300ms ease;
}

.nav-link.donation:hover {
  color: var(--text-color) !important;
  border: 1px solid var(--text-color) !important;
  background-color: transparent !important;
}

.dropdown-menu .dropdown-item:hover {
  background-color: transparent !important;
  color: #b5090e;
  transform: translateX(5px);
  transition: 0.5s;
}

.dropdown-menu li a {

  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  color: #777;
  font-weight: 400;
  text-transform: uppercase;
}

.dropdown-menu li a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  color: #777;
  font-weight: 400;
  text-transform: uppercase;
}

.navbar-nav {
  flex-direction: row;
  gap: 10px;
}

.navbar-nav .dropdown-menu {
  position: unset !important;
}
a.nav-link.active:hover {
  color: #00aff0;
}
a.nav-link:hover {
  color: #00aff0;
}
i.fa-solid.fa-chevron-down {
  color: black;
}
.nav-link-wrap {
  list-style: none;
  display: flex;
  align-items: center;
  margin-right: auto;
  gap: 29px;
  /* gap: 8px; */
}
@media (min-width: 768px) and (max-width: 1290px) {
  .nav-link-wrap {
    gap: 8px; /* Adjust the gap value as needed */
  }
}
.navbar-toggler {
  border: 0px;
  color: var(--accent-color);
}

.navbar-wrap {
  display: flex;
  align-items: center;
}

.nav-elements ul a {
  font-size: 16px;
  font-weight: 600;
  color: var(--text-color);
  text-decoration: none;
  letter-spacing: 0.5px;
}

/* .nav-link.active {
  color: var(--accent-color) !important;
  border-bottom: 1.5px solid var(--accent-color);
  padding-bottom: 6px;
} */

/* .nav-link.no-active {
    color: var(--accent-color);
    border-bottom: 1.5px solid var(--accent-color);
    padding-bottom: 6px;
  } */

.dropdown-item.active,
.dropdown-item:active {
  background-color: transparent !important;
  color: #2d1d1e;
}

.search-icon {
  background-color: var(--accent-color);
  color: var(--white);
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 18px;
}

.search-icon {
  cursor: pointer;
}
.search-result-item {
  margin-bottom: 8px;
}
.search-results-dropdown {
  border: 1px solid #ccc;
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
  /* margin-top: 5px; */
  background: white;
  position: absolute;
  width: 437px;
  z-index: 10;
  top: 55%;
  padding: 10px 16px;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 91%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.overlay-content {
  background: white;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 35%;
}

.search-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--white);
  border-radius: 25px;
  overflow: hidden;
  width: 100%;
}
.search-button .search {
  margin: 0px 6px 0px 0px;
}

.search-icon-left {
  margin-left: 10px;
  color: #aaa;
  display: none;
}

.search-input {
  padding: 10px;
  font-size: 16px;
  border: none;
  outline: none;
  width: 300px;
  flex-grow: 1;
}

.search-button {
  padding: 20px 20px 20px 25px;
  font-size: 16px;
  background-color: var(--accent-color);
  color: white;
  border: none;
  border-radius: 25px 25px 25px 25px;
  cursor: pointer;
  letter-spacing: 1.2px;
}

.close-button {
  padding: 6px;
  font-size: 25px;
  background-color: var(--white);
  color: #000;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  top: 45%;
  right: 28%;
}
.navbar-search-icon {
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .navbar{
    padding: 10px !important;
  }
  .navbar-text1 {
    display: none;
  }

  .navbar-wrap {
    display: none;
  }
}

@media (max-width: 992.98px) {
  .navbar-toggler {
    font-size: 25px;
  }

  .navbar-text1 {
    display: none;
  }

  .navbar-wrap {
    display: none;
  }
}

@media (min-width: 992px) {
  .navbar .nav-item:hover .dropdown-menu {
    display: block;
  }
}

@media (min-width: 993px) and (max-width: 1024.98px) {
  .navbar-wrap {
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .navbar-wrap {
    display: none;
  }
}

@media (max-width: 1199.99px) {


  .nav-link.donation.responsive {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 50px;
    margin-left: 0px;
    padding: 14px;
  }

  .offcanvas-body {
    padding: 0px 5px 0px 5px;
  }

  .nav-link-wrap.mobile {
    display: block;
  }

  .nav-link-wrap ul li {
    display: flex;
  }

  .offcanvas-body .navbar-nav {
    flex-direction: column;
  }

  .navbar-nav .nav-item .nav-link {
    padding: 8px 0px 12px 12px;
  }

  .navbar-nav .nav-item:not(:last-child) .nav-link {
    border-bottom: 1px solid var(--accent-color);
  }

  .offcanvas-body .navbar-nav .nav-item .dropdown-menu {
    background-color: #f7f7f7;
    width: auto;
    box-shadow: none;
  }

  .offcanvas-body .navbar-nav .nav-item .dropdown-menu {
    padding: 0px;
  }

  .offcanvas-body .navbar-nav .nav-item .dropdown-menu li a {
    /* color: #b5090e; */
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600;
    padding-left: 10px;
  }

  .offcanvas-body .navbar-nav .nav-item .dropdown-menu li:not(:last-child) a {
    border-bottom: 1px solid #0000001a;
  }

  li.nav-item {
    margin-top: 0px;
  }
  .offcanvas-header {
    margin-bottom: 20px;
  }
  .navbar-logo {
    width: 100%;
    height: 50px;
  }
  button.text-reset {
    border: 0px;
    background-color: transparent;
  }
  .offcanvas-close-btn {
    color: var(--accent-color) !important;
    font-size: 25px;
  }

  .overlay-content {
    width: auto;
  }
  .search-container {
    width: auto;
  }
}

@media (max-width: 576.98px) {
  .nav-text h6 {
    max-width: 175px;
  }

  .navbar-text1 {
    display: none;
  }

  .navbar-wrap {
    display: none;
  }
  .navbar-logo {
    height: 50px;
    width: 100%;
  }
  .navbar-toggler {
    font-size: 22px;
  }
  .overlay-content {
    width: auto;
  }
  .search-container {
    width: auto;
    flex-direction: column;
    align-items: start;
    border-radius: 12px;
  }
  .search-button {
    width: 100%;
    border-radius: 0px 0px 12px 12px;
  }
  .close-button {
    top: 35%;
    right: 5%;
  }
  .header {
    padding: 5px;
  }
}

a.dropdown-item {
  font-size: 14px !important;
  font-weight: 500 !important;
}
a.dropdown-item:hover {
  color: #00a0e3 !important;
}
.close-button {
  padding: 6px;
  font-size: 25px;
  background-color: var(--white);
  color: #000;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  top: 47%;
  /* right: 15%; */
}

.dropdown-toggle::after {
  display: none !important;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}


/* Responsive adjustments for smaller devices */
@media (max-width: 768px) {
  .navbar-logo {
      max-width: 120px; /* Reduce size for smaller screens */
      max-height: 50px;
  }
}

@media (max-width: 576px) {
    .navbar-logo {
        max-width: 112px;
        max-height: 46px;
    }
}


.nav-link-active::after {
  content: ""; /* Pseudo-element for underline */
  position: absolute;
  left: 0;
  bottom: -3px; /* Position below the text */
  width: 100%;
  height: 2px;
  background-color: #00aff0; /* Match active link color */
  transition: transform 0.3s ease; /* Smooth underline animation */
  transform: scaleX(1); /* Fully visible underline */
}

.nav-link:not(.nav-item-active)::after {
  content: ""; /* Add underline for non-active links */
  position: absolute;
  left: 0;
  bottom: -3px;
  width: 100%;
  height: 2px;
  background-color: #ccc; /* Neutral underline color */
  transform: scaleX(0); /* Initially hidden */
  transition: transform 0.3s ease;
}

.nav-link:not(.nav-item-active):hover::after {
  transform: scaleX(1); /* Show underline on hover */
  background-color: #00aff0; /* Hover underline color */
}


