.help-section {
  margin-top: 20px;
}

.help-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contact-info {
  margin-top: 10px;
  display: flex;
  gap: 15px;
}

.contact-info button {
  margin-right: 10px;
}

.appointment-box {
  display: flex;
  flex-direction: column;
}
.help-icon {
  width: 187px;
  height: 175px;
  /* margin-left: 76px; */
  margin-bottom: -17px;
}
.need-help {
  font-size: 30px !important;
  font-weight: 700 !important;
  color: white;
}

p.enquiries {
  font-size: 16px !important;
  font-weight: 400 !important;
  color: white;
}

.emargancy {
  background-color: white !important;
  color: black !important;
  width: 200px !important;
  height: 48px !important;
}

.Appointment {
  font-size: 24px !important;
  font-weight: 700 !important;
  color: white;
}
.printing {
  background-color: #354895 !important;
  width: 290px !important;
  height: 51px;
  color: white;
  border: none;
  border-radius: 8px;
  letter-spacing: 0.5px;
  font-size: 18px;
}
.need-help-sec {
  background-color: #354895;
  height: 220px;
  /* padding-left: 140px; */
  padding-left: 93px;
  padding-top: 46px;
  display: flex;
  gap: 28px;
  flex-wrap: wrap;
}
.book-appoiment {
  background-color: #01abeb;
  height: 220px;
  padding-right: 140px;
  padding-left: 44px;
  padding-top: 42px;
}
@media (max-width: 768px) {
  .need-help-sec {
    height: auto;
    justify-content: center;
  }
  .contact-info {
    margin-top: 10px;
    display: flex;
    gap: 15px;
    margin-bottom: 29px;
    flex-wrap: wrap;
  }
  .book-appoiment {
    text-align: center;
    padding-right: 0px;
    padding-left: 0px;
  }
  .need-help-sec-data{
    text-align: center;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .need-help-sec {
    height: auto;
  }
  .contact-info {
    margin-top: 10px;
    display: flex;
    gap: 15px;
    margin-bottom: 29px;
    flex-wrap: wrap;
  }
  .book-appoiment {
    text-align: center;
    padding-right: 0px;
    padding-left: 0px;
  }
}
