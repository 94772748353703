.appointmentpopup-contact-input {
    width: 100%;
    height: 56px;
    border-radius: 12px;
    border: 1px solid #d3d3d3;
    margin-top: 0.8rem;
    font-size: 15px;
    /* opacity: 63%; */
    padding-left: 1rem;
  }
  
  .appointmentpopup-contact-input-textarea {
    width: 100%;
    height: 120px;
    border-radius: 12px;
    border: 1px solid #d3d3d3;
    margin-top: 0.8rem;
    font-size: 15px;
    /* opacity: 63%; */
    padding: 1rem;
  }

  .react-select-container {
    width: 100%;
    height: 56px;
    border-radius: 12px;
    margin-top: 0.8rem;
}
  @media (max-width: 400px) { 
    .appointmentpopup-contact-input {
        height: 40px;
        font-size: 12px;
      }
      
      .appointmentpopup-contact-input-textarea {
        height: 80px;
        font-size: 12px;
      }
      .react-select-container {
        height: 40px;
        font-size: 12px;
    }
  }



.react-select__control {
    border: 1px solid #d3d3d3;
    border-radius: 12px !important;
    height: 100%;
    box-shadow: none;
    transition: border-color 0.3s;
    width: 100%;
}

.react-select__control:hover {
    border-color: #d3d3d3 !important;
    /* Keep the same border on hover */
    background-color: transparent !important;
    /* Remove hover background */
}

.react-select__control--is-focused {
    border-color: #d3d3d3 !important;
    /* Keep the border color gray on focus */
    box-shadow: none !important;
    /* Remove focus box shadow */
    background-color: transparent !important;
    /* Ensure background is transparent */
}

.react-select__control--is-focused {
    /* border-color: #00aff0; */
    box-shadow: 0 0 0 2px rgba(0, 175, 240, 0.2);
}

.react-select__value-container {
    padding: 0 8px;
}

.react-select__placeholder {
    font-size: 16px;
    color: #aaa;
}

.react-select__menu {
    border-radius: 4px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1050;
}

.react-select__option {
    padding: 8px 12px;
    font-size: 16px;
}

.react-select__option--is-selected {
    /* background-color: #00aff0; */
    color: white;
}

.react-select__option--is-focused {
    background-color: rgba(0, 175, 240, 0.1);
}

.react-select__dropdown-indicator {
    color: #aaa;
}