/* General Container Styling */
.home-booking-container {
  padding: 0px;
  margin: auto;
  width: 100%;
}
.slider-text {
  position: absolute;
  top: 20%;
  left: 10%;
  transform: translate(-10%, -20%);
  color: white;
  text-align: left;
  /* max-width: 80%; */ 
  z-index: 1; /* Ensure text is on top of the image */
}
.slider-text h1 {
  letter-spacing: 0.2px;
}

/* Slider Heading */
.slider-heading {
  font-size: 60px;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 20px;
  color: #091b63;
}

.slider-p {
  font-size: 34px;
  font-weight: 500;
  color: black;
  margin-bottom: 20px;
}
/* Custom Dots Container */
.custom-dots {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column; /* Arrange dots vertically */
  gap: 10px; /* Adjust space between dots */
}
.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 1px;
  margin: 2px;
  list-style: none;
  text-align: center;

  top: 40%;
}
.slick-dots li {
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
  rotate: 28;
  transform: rotate(90deg);
}
a {
  text-decoration: none;
  color: #354895;
}
.custom-dots ul {
  display: block !important;
  flex-direction: column;
  padding: 0;
  margin: 0;
  list-style: none;
}

.custom-dots ul li {
  margin-bottom: 8px;
}

.custom-dots ul li button {
  background: none;
  border: none;
  color: transparent;
}

.custom-dots ul li.slick-active button {
  background: #01adec;
}

.custom-dots ul li button:before {
  font-size: 12px;
  line-height: 12px;
  color: #01adec;
}

.dot {
  width: 26px;
  height: 5px;
  border-radius: 3px;
  background-color: #01adec;
  opacity: 0.5;
  margin-top: 14px;
}

.slick-active .dot {
  opacity: 1;
}
h2.specialties-title {
  font-size: 20px;
  font-weight: 500;
  color: #354895;
  text-transform: uppercase;
}

/* Search Field Styling */
.search-field-11 {
  width: 100%;
  max-width: 532px;
  z-index: 10;
  /* margin: 0 auto; */
  background-color: #fff;

  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px 8px 0px 0px;

  font-size: 16px;
}
.css-1wfizua-MuiInputBase-root-MuiOutlinedInput-root {
 border-radius: 4px 4px 0px 0px !important;
}
.css-eb2wq1 {
  border-radius: 4px 4px 0px 0px !important;
}
/* .box-postions {
  position: absolute;
  left: 15%;
  top: 80%;
} */

/* Slider Styling */
.slider-container {
  position: relative;
  overflow: hidden;
  height: 700px; /* Fixed height */
  width: 100%; /* Ensure it takes up the full available width */
}

.slick-list {
  overflow: hidden !important;
}

.slider-image {
  width: 100%;
  height: 800px;
  object-fit: fill;
  display: block;
  outline: none;
  border: none;
}
.slider-image:focus {
  outline: none;
}
.slick-slide:has(.slider-image:focus),
.slick-slide div:has(.slider-image:focus),
.slick-slide img.slider-image:focus {
  outline: none;
  border: none;
}

.slider-text {
  position: absolute;
  top: 65%;
  left: 18%;
  transform: translate(-10%, -20%);
  color: white;
  text-align: left;
  /* max-width: 80%; */
  width: 100%;
  z-index: 1; /* Ensure text is on top of the image */
}
.box-postions {
  padding: 10px 128px;
  margin-top: -72px;
  position: relative;
}

/* Responsive Design */
@media (max-width: 768px) {
  .slider-container {
    height: 375px; /* Adjust height on smaller screens */
  }

  .slider-text {
    top: 36%; /* Adjust text position for smaller screens */
    left: 5%;
    transform: translate(-5%, -30%);
  }
  .specialties-section {
    padding: 26px !important;
    margin-bottom: 10px !important;
  }

  .slider-image {
    height: 360px; /* Adjust image height for smaller screens */
  }
  .slider-p {
    font-size: 18px !important;
    margin-bottom: 0 !important;
  }
  .slider-heading {
    line-height: 1.2 !important;
    /* font-size: 30px; */
    margin-top: 0 !important;
  }
  .key-facts-container {
    padding: 26px !important;
  }
  .need-help-sec {
    padding-left: 26px !important;
  }
  .search-field-11 .MuiInputBase-input {
    /* padding: 13.5px 8px !important; */
    font-size: 12px !important;
  }
  .box-heading {
    font-size: 16px !important;
  }
  .box-text {
    font-size: 12px !important;
  }
}
@media (max-width: 576px) {
  .key-fact-img {
    display: none;
  }
}
@media (max-width: 480px) {
  .slider-container {
    height: 260px; /* Adjust height for very small screens */
  }
  .specialties-box .specialty-heading {
    font-size: 14px !important;
  }

  .slider-text {
    top: 51%; /* Adjust text position for mobile */
    left: 10%;
    width: 32%;
    transform: translate(-5%, -40%);
  }
  .search-field-11 .css-1wfizua-MuiInputBase-root-MuiOutlinedInput-root{
    height: 16px !important;
    font-size: 12px;
  } 
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.css-eb2wq1 {
    height: 16px;
  }
  .specialties-box {
    padding-top: 11px !important;
    height: 150px !important;
    padding: 11px 0 0 0  !important;
  }

  .slider-image {
    height: 222px; /* Adjust image height for mobile */
  }
  .slider-heading {
    font-size: 19px;
    margin-bottom: 6px !important;
  }
  .slider-p {
    margin-top: 0 !important;
    font-size: 19px;
  }
  .search-field-11 {
    /* width: 60%; */
    height: 16px;
  }
  .searchIcon {
    width: 12px !important;
  }
  .close-icon {
    width: 16px !important;
  }
  .search-field-11 .MuiInputBase-input {
    padding: 8.5px 8px !important;
    font-size: 8px !important;
  }
  .box-heading {
    font-size: 14px !important;
    line-height: 1.5 !important;
  }
  .box {
    gap: 11px !important;
    height: 86px !important;
  }
  .box-image {
    width: 27px !important;
    /* height: 27px !important; */
  }
  .box-text {
    font-size: 12px !important;
  }
  h2.specialties-title {
    font-size: 18px !important;
  }
  .personalized-text {
    font-size: 28px !important;
    line-height: 41px !important;
  }
  .key-spec-sec {
    margin-top: 20px !important;
  }
  .key-facts-container h1 {
    font-size: 28px !important;
  }
  .key-facts-container H3 {
    font-size: 17px !important;
  }
  .need-help-sec {
    justify-content: center !important;
  }
  .contact-info {
    justify-content: center;
  }
  .MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root.search-field-11.mt-3.mb-4.css-cmpglg-MuiFormControl-root-MuiTextField-root {
    width: 184px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 768px) {
  .slider-text {
    top: 56%; /* Adjust text position for smaller screens */
    left: 10%;
    width: 34%;
    transform: translate(-5%, -30%);
  }
  .slider-heading {
    font-size: 24px;
  }
  .search-field-11 {
    /* width: 80%; */
    height: 20px;
  }
  .search-field-11 .css-1wfizua-MuiInputBase-root-MuiOutlinedInput-root{
    height: 20px !important;
  }
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.css-eb2wq1 {
    height: 20px;
  }
  .searchIcon {
    width: 18px !important;
  }
  .close-icon {
    width: 18px !important;
  }
}
.healthcare {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.personalized-text {
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
}
.specialties-box {
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  box-shadow: 0px 2px 4px #a5a5a526;
  padding: 15px;
  height: 200px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: block;
  margin: auto;
  padding-top: 39px;
  cursor: pointer;
}
.view-all-btn {
  width: 95px;
  height: 35px;
  background-color: white;
  border: 1px solid #354895;
  box-shadow: 0px 5px 15px #ababab40;
  color: #354895;
  border-radius: 12px;
}
.special-image {
  width: 60px;
  margin-bottom: 18px;
}
.specialty-heading {
  font-size: 20px !important;
  font-weight: 700 !important;
  line-height: 21px !important;
}
.specialties-box:hover {
  transform: translateY(-5px);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
}
 
.search-field-11 .css-1wfizua-MuiInputBase-root-MuiOutlinedInput-root {
  padding-right: 6px;
  /* height: 35px; */
}
.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.css-eb2wq1 {
  padding-right: 6px;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .slider-image {
    height: 400px;
  }
  .slider-heading {
    font-size: 32px;
    margin-top: 0px !important;
  }
  .slider-text {
    top: 58%;
    left: 11%;
    width: 33%;
  }
  .img-fluid {
    text-align: center;
  }
  .slider-p {
    font-size: 30px;
    margin-bottom: 0 !important;
  }
  .slider-container {
    height: 400px;
  }
  .personalized-text {
    font-size: 34px;
  }
  h2.specialties-title {
    font-size: 16px;
  }
  .search-field-11 {
    /* width: 80%; */
    z-index: 100;
  }
  .search-field-11 .css-1wfizua-MuiInputBase-root-MuiOutlinedInput-root{
    height: 30px;
  }
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.css-eb2wq1 {
    height: 20px !important;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1536px) {
  .box-postions {
    padding: 10px 121px;
  }
  .slider-text h1 {
    letter-spacing: 1.2px;
    margin-left: 161px;
    font-size: 52px;
  }
  .slider-p {
    margin-left: 165px;
  }
  .slider-text {
    position: absolute;
    top: 68%;
    left: 26%;
    transform: translate(-50%, -50%);
    text-align: left;
    color: #091b63;
    width: 36%;
  }
  .carousel-indicators {
    position: absolute;
    top: 35%;
    left: -157px;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
}
/* Container */
.key-facts-container {
  padding: 86px 140px;
  background: linear-gradient(to bottom, #00aff0, #263985);
  color: white;
}
.key-facts-container H3 {
  font-size: 20px;
}
.key-facts-container h1 {
  font-weight: 700;
  font-size: 40px;
}
/* Content Section */
.key-facts-content h3 {
  font-size: 20px;
  margin-bottom: 10px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.key-facts-content h1 {
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
}

/* Grid Section */
.home-keyfact-img {
  text-align: end;
}
.facts-grid {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: 49px;
}

.fact-box {
  background: white;
  color: #004aad;
  text-align: center;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  flex: 0 1 auto;
  height: 210px;
}
.key-fact-img {
  width: 67%;
}
.fact-box img {
  width: 67px;
  height: 67px; /* Adjust size of the icons */
  margin-bottom: 10px;
}

.fact-box h2 {
  font-size: 36px;
  font-weight: 700;
  margin: 5px 0;
  color: black;
}

.fact-box p {
  font-size: 16px;
  color: black;
  font-weight: 400;
}

/* Image Section */
.key-facts-image img {
  max-width: 100%;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Responsive */
@media (max-width: 768px) {
  .home-keyfact-img {
    text-align: end;
    margin-top: -40px !important;
  }
  .home-keyfact-img .key-fact-img {
    width: 47%;
  }
  .key-facts-container {
    text-align: center;
  }

  .key-facts-image {
    margin-top: 20px;
  }

  .key-facts-content h1 {
    font-size: 28px;
  }
  .img-fluid {
    text-align: center;
  }

  /* .view-all-btn {
    margin-left: auto;
    margin-right: auto;
  } */
  .custom-dots {
    display: none;
  }
  .personalized-text {
    font-size: 30px;
  }
  h2.specialties-title {
    font-size: 18px !important;
  }
  .searchIcon {
    width: 20px;
  }
  .close-icon {
    width: 20px;
  }
}

@media (max-width: 1440px) {
  .key-facts-content h1 {
    font-size: 30px;
  }
  .custom-dots {
    display: none;
  }
}
@media (min-width: 1024px) and (max-width: 1536px) {
  .key-fact-img {
    width: 87%;
  }
}


.slider-dropdown {
  position: absolute; /* Make it positioned relative to the closest positioned ancestor */
  left: 50%;
  top: 100%;
  transform: translateX(-50%); /* Adjust for perfect centering */
  z-index: 999; /* Ensure it appears above all other components */
  background-color: white;
  border: 1px solid #ddd; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 8px 8px;
  width: 100%;
  max-height: 180px;
  overflow: auto
}

.slider-dropdown-group {
  margin-bottom: 15px; 
}

.slider-dropdown-header {
  font-weight: 700;
  font-size: 18px;
  color: #354895; 
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow at bottom only */
}

.slider-dropdown-item {
  display: flex;
  align-items: center;
  gap: 10px; 
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.slider-dropdown-item:hover {
  background-color: #d7d7d7; /* Highlight on hover */
}

.slider-dropdown-item img {
  width: 40px; /* Adjust image size */
  height: 40px;
  border-radius: 50%; /* Circular images */
  object-fit: cover;
}

.slider-dropdown-item span {
  font-size: 14px;
  color: #000;
}
.no-results-found-p {
  font-size: 18px;
  color: #000;
  text-align: center;
  padding: 10px;
}

/* Custom scrollbar for Webkit browsers */
.slider-dropdown::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar */
}

.slider-dropdown::-webkit-scrollbar-track {
  background: #f0f0f0; /* Background of the scrollbar track */
  border-radius: 4px; /* Rounded corners for the track */
}

.slider-dropdown::-webkit-scrollbar-thumb {
  background: #b0b0b0; /* Scrollbar thumb color */
  border-radius: 4px; /* Rounded corners for the thumb */
}

.slider-dropdown::-webkit-scrollbar-thumb:hover {
  background: #909090; /* Thumb color on hover */
}

@media (max-width: 768px) {
  .slider-dropdown {
    height: 100px;
    width: 100%;
  }
  .slider-dropdown-header {
    font-weight: 500;
    font-size: 16px;
    padding: 8px;
  }
  .slider-dropdown-item span {
    font-size: 12px;
  }
  .slider-dropdown-item img {
    width: 20px; /* Adjust image size */
    height: 20px;
  }
}

@media (max-width: 480px) {
  .slider-dropdown {
    height: 100px; /* For very small devices: Reduced further */
    width: 100%;
  }
}
@media (max-width: 576px) {
  .slider-dropdown-header {
    font-size: 12px;
    padding: 2px 6px;
  }
  .slider-dropdown-item {
    padding: 4px 8px;
  }
}



