:root {
  --accent-color: #354895;
  --secondary-color: #00AFF0;
  --white: #ffffff;
  --contact-primary: #011632;
  --contact-secondary: #333333;
}

.header {
  background-color: var(--accent-color);
  padding: 5px 140px; /* Adjust padding for smaller screens */
}

.header-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.header-left {
  display: flex;
  gap: 8px;
  margin-bottom: 6px; /* Add spacing for stacked layout */
}

.social-icon {
  color: var(--secondary-color);
  background-color: var(--white);
  width: 20px;
  padding: 3px;
  border-radius: 50%;
  height: 20px;
}
.info-icon {
  color: var(--accent-color);
  width: 28px;
  padding: 4px;
  border-radius: 50%;
  height: 28px;
}
.header-right {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  align-items: center;
}

.header-right h6 {
  font-size: 12px;
  color: white;
  letter-spacing: 1px;
  font-weight: 400;
  margin: 0;
}

.info-icon {
  width: 28px;
  height: 28px;
}

.info-details {
  display: flex;
  gap: 4px;
  align-items: center;
}

.anchor{
  color: #ffffff;
}

.anchor:hover,.anchor:active{
  color: #ffffff;
}


@media (max-width: 768px) {
  .header {
    padding: 10px;
  }

  .header-left,
  .header-right {
    justify-content: center;
    gap: 16px;
  }

  .header-right h6 {
    font-size: 10px;
  }

  .info-icon {
    width: 24px;
    height: 24px;
  }
}

@media (max-width: 480px) {
  .header {
    padding: 5px;
  }

  .header-wrap {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .header-right,
  .header-left {
    /* flex-direction: column; */
    gap: 10px;
  }

  .header-right h6 {
    font-size: 10px;
  }
}
