.shimmer-wrapper {
    position: relative;
    overflow: hidden;
    background-color: #f6f7f8;
    border-radius: 4px;
  }
  
  .shimmer {
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to right,
      #f6f7f8 0%,
      #edeef1 50%,
      #f6f7f8 100%
    );
    background-size: 200% 100%;
    animation: shimmer-animation 1.5s infinite;
  }
  
  @keyframes shimmer-animation {
    0% {
      background-position: -200% 0;
    }
    100% {
      background-position: 200% 0;
    }
  }
  