/* General Container Styling */

/* Styling for the Grid Container */
.booking-home {
  display: flex;
  justify-content: space-between;
}
.booking-home1 {
  position: absolute;
  left: 15%;
  top: 78%;
}
.MuiInputBase-input{
  padding: 16.5px 14px !important;
}
/* Styling for Boxes */
.box {
  display: flex;
  background-color: #354895;
  color: white;
  height: 120px;
  /* max-width: 200px; */
  border-radius: 10px;
  align-items: center;
  padding: 0 16px;
  text-align: center;
  overflow: hidden;
  cursor: pointer;
  justify-content: center;
  gap: 26px;
}
.box:hover {
  background-color: #01adec;
  color: white;
  transition: background-color 2s ease, color 2s ease; /* Increased transition duration to 2 seconds */
}

.box-image {
  width: 40px;
  /* height: 40px; */
}

.heading-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
}

.box-heading {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  line-height: 1.2 !important;
}

.box-text {
  font-size: 15px;
  font-weight: 400;
}
.box-postions.no-margin {
  margin-top: 30px; /* Override margin for no-margin class */
}
/* Responsive Adjustments */
@media (max-width: 1024px) {
  .booking-home1 {
    position: absolute;
    left: 8%;
    top: 90%;
  }
}

@media(max-width:768px){
  .box-postions {
    padding: 10px 26px;
    margin-top: 20px;
  }
  .emergency-box {
    padding: 0px !important;
  }
}

@media (max-width: 480px) {
  .box-postions {
    padding: 10px 26px;
    margin-top: 0px;
}
  
}
