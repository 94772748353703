.contact-div {
  width: 100%;
  padding: 63px 128px;
  color: var(--contact-primary);
}

.ContactDetail-box {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  height: 551px;
  padding: 38px;
  border-radius: 12px;
}

.contact-form-div {
  /* width: 406px; */
  margin: auto;
  padding: 0px 34px;
}

.contact-title {
  font-weight: 700;
  font-size: 26px;
  margin-bottom: 19px;
}

.border-primary-subtle {
  border-color: #354895 !important;
}

.contact-add {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--contact-secondary);
}

.contact-input {
  width: 100%;
  height: 56px;
  border-radius: 12px;
  border: 1px solid #d3d3d3;
  margin-top: 0.8rem;
  opacity: 63%;
  padding-left: 1rem;
}

.contact-input-textarea {
  width: 100%;
  height: 120px;
  border-radius: 12px;
  border: 1px solid #d3d3d3;
  margin-top: 0.8rem;
  opacity: 63%;
  padding: 1rem;
}

.contact-submit-btn {
  width: 100%;
  height: 66px;
  border-radius: 32.5px;
  background-color: #354895;
  color: #ffffff;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin-top: 1.5rem;
  letter-spacing: 0.2px;
  border: none;
}

.contactDatamap iframe {
  width: 100%;
}

.contact-map iframe {
  border: 0;
  top: 0;
  position: absolute;

}

@media(max-width:576px) {
  .contact-page {
    margin-top: 0 !important;
  }

  .ContactDetail-box {
    padding: 28px;
  }

  .contact-title {
    font-size: 22px;
  }

  .contact-add {
    font-size: 15px;
  }
}

@media (max-width: 576px) {
  .contact-submit-btn {
    font-size: 18px;
    width: 50%;
    height: 48px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

@media(max-width:768px) {
  .ContactDetail-box {
    height: auto;
  }

  .contact-form-title {
    font-size: 24px !important;
  }

  .contact-div {
    padding: 26px;
  }
}

@media(max-width:1200px) {
  .ContactDetail-box {
    height: auto;
  }

  .contact-form-title {
    font-size: 28px !important;
  }
}

.anchorBlack {
  color: var(--contact-secondary);
}

.contact-numbers {
  font-weight: bold;
}

.margin-bottom-5px {
  margin-bottom: 5px;
}